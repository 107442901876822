var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.fetched[_vm.level]
        ? _c(
            "v-btn",
            {
              staticClass: "text--secondary ml-2",
              attrs: {
                loading: _vm.fetching[_vm.level],
                fab: "",
                small: "",
                disabled: "",
                text: "",
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
            1
          )
        : _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "offset-overflow": "",
                "close-on-content-click": false,
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "text--secondary ml-2",
                              attrs: { fab: "", small: "", text: "" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-card",
                [
                  _vm.contacts.length === 0
                    ? _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pr-10 opacity-7" },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-alert-circle-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("No contacts found"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.$isError(_vm.contacts)
                    ? _c(
                        "v-list",
                        { attrs: { subheader: "" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.labels[_vm.level])),
                          ]),
                          _c(
                            "v-list-item",
                            { staticClass: "pr-10 warning--text opacity-7" },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alert-circle-outline")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Error during contact retreval"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-list",
                        { attrs: { "two-line": "", subheader: "" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.labels[_vm.level])),
                          ]),
                          _vm._l(
                            _vm.contacts,
                            function ({ full_name, email }, j) {
                              return _c(
                                "v-list-item",
                                { key: `contact-${j}` },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(full_name)),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(email)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("copy-to-clipboard", {
                                        attrs: {
                                          textToCopy: email,
                                          iconButton: true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }